<template>
    <section :class="sectionClass">
        <div class="message">
            We use cookies and similar technologies to improve your experience on our site. To learn more about this, view our
            <a class="link" href="https://africastalking.com/privacy_policy" target="_blank">Privacy Policy</a>
        </div>
        <div class="buttons">
            <button role="button" class="btn" v-on:click.prevent="accept">Got it!</button>
        </div>
    </section>
</template>

<script>
import { setCookie, getCookie } from '@/utils';

const COOKIE_KEY = 'vue-cookie-accept-decline-cookie-consent';

export default {
    data() {
        return {
            sectionClass: 'hidden',
        };
    },

    created() {
        const consent = getCookie(COOKIE_KEY);
        if (!consent) {
            this.sectionClass = 'visible';
        } else {
            this.sectionClass = 'hidden';
        }
    },

    methods: {
        accept() {
            setCookie(COOKIE_KEY, 'accept');
            this.sectionClass = 'hidden';
        },
    },
};
</script>

<style scoped>
.visible {
        position: fixed;
        display: block;
        bottom: 20px;
        left: 20px;
		margin: 0 0;
        max-width: 300px;
        background: #fafafa;
		padding: 0px;
        border-radius: 6px;
		justify-content: space-between;
		border: .5px solid #ccc;
        box-shadow:  0 -2px 16px rgba(0, 0, 0, 0.034);
		line-height: 1.5;
        z-index: 9999;
		opacity: 1;
		transition: all .3s ease;
		-webkit-transition: all .3s ease;
		-moz-transition:    all .3s ease;
		-o-transition:      all .3s ease;
		-ms-transition:     all .3s ease;
}

.hidden {
    display: none;
    opacity: 0;
    transition: all 200ms ease;
    -webkit-transition: all 200s ease;
    -moz-transition:    all 200s ease;
    -o-transition:      all 200s ease;
    -ms-transition:     all 200s ease;
}

.message{
    padding: 15px 20px;
    font-size: .9rem;
    max-height: 100px;
    overflow: auto;
    margin-bottom: 10px;
    color: #000000;
}

.buttons{
    text-align: center;
    min-height: 40px;
    margin-bottom: 0;
}

.btn {
    display: inline-block;
    padding: 10px 0px;
    width: 100%;
    font-size: .9rem;
    /* border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; */
    outline: none;
    color: #4caf50;
    font-weight: 700;
    white-space: nowrap;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #eee;
    line-height: 1.5;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn:hover{
    background-color: #409343;
    color: #eee;
}
</style>
