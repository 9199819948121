import { submit as submitSimulatorData } from '../../../utils/api/socket/simulator';

const defaultState = {
    active: false,
    text: '',
    transactionId: '',
    status: '',
};

const mutations = {
    closeUssd(state) {
        state.active = false;
        state.text = '';
        state.transactionId = '';
        state.status = '';
    },

    setUssd(state, payload) {
        if (state.transactionId === '') {
            state.active = true;
            state.text = payload.text;
            state.transactionId = payload.transactionId;
            state.status = payload.status;
            return;
        }

        if (state.transactionId === payload.transactionId) {
            state.text = payload.text;
            state.status = payload.status;
        }
    },

    ussdLoading(state) {
        state.active = true;
        state.text = '';
        state.status = '';
    },
};

const actions = {
    async sendUssd({ commit, state, rootState }, { inputText }) {
        try {
            const ussdData = {
                messageType: 'UssdRequest',
                phoneNumber: rootState.simulator.phoneNumber,
                payload: JSON.stringify({
                    inputText,
                    ...(state.transactionId && {
                        transactionId: state.transactionId,
                    }),
                }),
            };
            await submitSimulatorData(ussdData);
            commit('ussdLoading');
        } catch (ex) {
            console.error(ex);
            commit('closeUssd');
        }
    },

    receiveUssd({ commit }, payload) {
        commit('setUssd', payload);
    },
};

const getters = {
    processing: (state) => state.active === true && state.text.length === 0,
    hasMenu: (state) => state.active === true && state.text.length > 0,
    acceptsInput: (state) => state.active === true && state.status === 'CON',
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters,
};
