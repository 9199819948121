import { submit as submitSimulatorData } from '../../../utils/api/socket/simulator';

const defaultState = {
    sendPaymentError: '',
};

const mutations = {
    setSendPaymentError(state, data) {
        state.sendError = data;
    },
};

const actions = {
    async sendPayment({ commit, rootState }, payment) {
        try {
            commit('setSendPaymentError', '');
            const sendPaymentData = {
                messageType: 'MobilePaymentC2BRequest',
                phoneNumber: rootState.simulator.phoneNumber,
                payload: JSON.stringify(payment),
            };
            await submitSimulatorData(sendPaymentData);
        } catch (ex) {
            console.error(ex);
            commit('setSendPaymentError', ex);
        }
    },
};

export default {
    namespaced: true,
    state: defaultState,
    actions,
    mutations,
};
