/* global umami */
import { submit as submitSimulatorData } from '../../../utils/api/socket/simulator';

const defaultState = {
    messages: {},
    threads: {},
    activeThreadId: null,
    unreadMessages: 0,
};

const getters = {
    messageThreads: (state) => Object
        .keys(state.threads).map((key) => {
            const threadMessagesLength = state.threads[key].messages.length;
            const lastThreadMessageId = state.threads[key].messages[threadMessagesLength - 1];
            const thread = {
                id: key,
                message: state.messages[lastThreadMessageId],
                unread: state.threads[key].unread,
            };
            return thread;
        }).sort((a, b) => {
            if (a.message.createdAt > b.message.createdAt) return -1;
            if (b.message.createdAt > a.message.createdAt) return 1;
            return 0;
        }),

    activeThreadMessages: (state) => {
        if (state.activeThreadId === null) return [];
        const thread = state.threads[state.activeThreadId];
        const messages = thread.messages.map((messageId) => state.messages[messageId]);
        return messages;
    },

    unreadMessages: (state) => state.unreadMessages,
};

const mutations = {
    createMessage(state, payload) {
        state.messages = {
            ...state.messages,
            [payload.id]: payload,
        };

        const threadId = payload.direction === 'outgoing'
            ? payload.to
            : payload.from;
        const thread = state.threads[threadId];
        if (thread === undefined) {
            const newThread = {
                id: threadId,
                messages: [payload.id],
                unread: payload.direction === 'outgoing'
                    ? 0
                    : 1,
            };

            state.threads = {
                ...state.threads,
                [threadId]: newThread,
            };
        } else {
            thread.messages.push(payload.id);
            if (payload.direction === 'incoming') {
                thread.unread += 1;
            }

            state.threads = {
                ...state.threads,
                [threadId]: thread,
            };
        }

        state.unreadMessages = Object
            .keys(state.threads)
            .reduce((sum, key) => sum + state.threads[key].unread, 0);
    },

    sendMessageSuccess(state, payload) {
        const sms = state.messages[payload.id];
        const updatedSms = {
            ...sms,
            ...payload,
        };
        state.messages = {
            ...state.messages,
            [payload.id]: updatedSms,
        };
    },

    sendMessageFailure(state, payload) {
        const sms = state.messages[payload.id];
        const updatedSms = {
            ...sms,
            ...payload,
        };
        state.messages = {
            ...state.messages,
            [payload.id]: updatedSms,
        };
    },

    setActiveThreadId(state, threadId) {
        state.activeThreadId = threadId;
    },

    markThreadRead(state, threadId) {
        const thread = state.threads[threadId];
        thread.unread = 0;
        state.threads = {
            ...state.threads,
            [threadId]: thread,
        };
        state.unreadMessages = Object.keys(state.threads)
            .reduce((sum, key) => sum + state.threads[key].unread, 0);
    },
};

const actions = {
    async sendMessage({ commit, rootState }, { to, message }) {
        let newMessageId;
        try {
            const newMessage = {
                id: `outgoing::${Date.now()}`,
                to,
                message,
                direction: 'outgoing',
                sent: false,
                read: true,
                createdAt: Date.now(),
            };
            newMessageId = newMessage.id;
            commit('createMessage', newMessage);
            const sendMessageData = {
                messageType: 'IncomingSms',
                phoneNumber: rootState.simulator.phoneNumber,
                payload: JSON.stringify({
                    to,
                    text: message,
                }),
            };
            await submitSimulatorData(sendMessageData);
            commit('sendMessageSuccess', {
                id: newMessageId,
                sent: true,
            });
            commit('setActiveThreadId', to);

            umami.track('simulator-sent-message', 'sandbox');
        } catch (ex) {
            commit('sendMessageFailure', {
                id: newMessageId,
                sent: false,
            });
            commit('setActiveThreadId', to);
        }
    },

    receiveSms({ commit }, { from, text }) {
        const newMessage = {
            id: `incoming::sms::${Date.now()}`,
            from,
            message: text,
            direction: 'incoming',
            read: false,
            createdAt: Date.now(),
        };
        commit('createMessage', newMessage);
        umami.track('simulator-received-sms', 'sandbox');
    },

    receiveAirtime({ commit }, { currencyCode, amount }) {
        const newMessage = {
            id: `incoming::airtime::${Date.now()}`,
            from: 'AIRTIME',
            message: `You have received ${currencyCode} ${amount} worth of airtime.`,
            direction: 'incoming',
            read: false,
            createdAt: Date.now(),
        };
        commit('createMessage', newMessage);
        umami.track('simulator-received-airtime', 'sandbox');
    },

    receiveMobileData({ commit }, { description }) {
        const newMessage = {
            id: `incoming::mobiledata::${Date.now()}`,
            from: 'MOBILE DATA',
            message: description.replace(/\[Test Service\]/g, '').trim(),
            direction: 'incoming',
            read: false,
            createdAt: Date.now(),
        };
        commit('createMessage', newMessage);
        umami.track('simulator-received-mobiledata', 'sandbox');
    },

    receivePayment({ commit }, { description }) {
        const newMessage = {
            id: `incoming::payment::${Date.now()}`,
            from: 'PAYMENT',
            message: description,
            direction: 'incoming',
            read: false,
            createdAt: Date.now(),
        };
        commit('createMessage', newMessage);
        umami.track('simulator-received-payment', 'sandbox');
    },
};

export default {
    namespaced: true,
    state: defaultState,
    getters,
    actions,
    mutations,
};
