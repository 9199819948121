var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative z-40 md:z-70"},[_c('div',{staticClass:"app-header w-screen fixed top-0",class:{
            'bg-orange-gradient': !_vm.isTransparent,
            'bg-transparent': _vm.isTransparent,
        }},[_c('div',{staticClass:"flex flex-row h-16 items-center justify-between",class:{
                'w-12/12': !_vm.isFullWidth,
                'mx-auto': !_vm.isFullWidth,
            }},[_c('div',{staticClass:"h-full px-4 flex flex-row lg:w-sideBarWidthLg xl:w-sideBarWidthXl items-center text-white"},[_c('menuSvg',{staticClass:"h-8 fill-current lg:hidden mr-4",on:{"click":function($event){return _vm.emitOpenSidebar()}}}),_c('router-link',{staticClass:"flex h-8",attrs:{"to":"/"}},[_c('logoSvg',{staticClass:"h-full fill-current"})],1)],1),_c('div',{staticClass:"relative h-full flex-grow px-4 flex flex-row justify-end items-center"},[(_vm.withSearchBar && !_vm.isMobile)?_c('AppSearch',{staticClass:"hidden sm:block absolute transform-center-x",attrs:{"placement":'header'}}):_vm._e(),(!_vm.sessionActive)?_c('button',{staticClass:"bg-transparent border text-white py-1 px-4 ml-4 border-white rounded-md",on:{"click":_vm.doSignIn}},[_vm._v(" Sign In ")]):_vm._e(),(_vm.sessionActive)?_c('div',{staticClass:"relative"},[_c('ProfileId',{staticClass:"cursor-pointer",attrs:{"profile":_vm.user,"size":'small',"theme":'light',"compact":true},nativeOn:{"click":function($event){return _vm.openAvatarMenu.apply(null, arguments)}}}),_c('ul',{ref:"avatarMenu",staticClass:"avatar-menu transition duration-150 absolute right-0 bg-white shadow-md rounded-md overflow-hidden min-w-48"},[_c('li',[_c('div',{staticClass:"flex flex-col px-4 py-2 text-gray-800"},[_c('span',{staticClass:"text-base"},[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName))]),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.user.email))])])]),_vm._m(0)])],1):_vm._e()],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"px-4 py-2 block border-t cursor-pointer text-sm text-gray-700 bg-white hover:bg-gray-200",attrs:{"href":"https://account.africastalking.com","target":"_blank"}},[_vm._v(" Account ")])])
}]

export { render, staticRenderFns }