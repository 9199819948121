import { APP_CONFIG, EventBus } from '../..';
import { getAccessToken } from '../../auth';

export default async function invoke(
    method,
    path,
    data = undefined,
    options_ = {},
) {
    const options = {
        baseUrl: APP_CONFIG.yodaApiUrl,
        timeout: APP_CONFIG.timeout,
        headers: {},
        json: true,
        ...options_,
    };

    const headers = {
        Accept: 'application/json',
        'X-Client-Id': `${APP_CONFIG.clientId}`,
    };

    const token = getAccessToken();
    if (token !== undefined) {
        headers.Authorization = `Bearer ${token}`;
    }

    if (options.headers) {
        Object.entries(options.headers)
            .forEach(([key, value]) => {
                headers[key] = value;
            });
    }

    const opts = {
        method,
        headers,
    };

    if (data) {
        if (opts.headers['Content-Type'] === undefined && options.json === true) {
            opts.headers['Content-Type'] = 'application/json';
            opts.body = JSON.stringify(data);
        }

        if (options.json === false) {
            opts.body = data;
        }
    }

    const url = `${options.baseUrl}${path}`;

    const res = await Promise.race([
        fetch(url, opts),
        new Promise((resolve, reject) => setTimeout(
            () => reject(new Error('timeout')), options.timeout || 25000,
        )),
    ]);

    if (!(res.status >= 200 && res.status < 300)) {
        if (res.status === 401 && token !== undefined) {
            EventBus.$emit('expiredToken');
            const error = new Error('Expired Token');
            error.name = 'ExpiredToken';
            throw error;
        }

        const response = await res.json();
        throw response.message;
    }

    // const delay = () => new Promise((resolve) => {
    //     setTimeout(() => resolve(), 5000);
    // });
    // await delay();

    const result = await res.json();
    return result.data ? result.data : result;
}
