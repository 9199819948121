import {
    search,
} from '../../utils/api/http/search';

import { generateMutations } from '../utils';

const defaultState = {
    isSearching: false,
    docs: [],
    tutorials: [],
};

const mutations = generateMutations(defaultState);

const actions = {
    async search({ commit, dispatch }, query) {
        try {
            commit('setIsSearching', true);
            const { courses, docs } = await search(query);
            commit('setDocs', docs);
            commit('setTutorials', courses);
            commit('setIsSearching', false);
        } catch (ex) {
            if (ex.name === 'ExpiredToken') {
                await new Promise((resolve) => {
                    setTimeout(() => {
                        dispatch('search', query);
                        resolve();
                    }, 1000);
                });
            } else {
                throw ex;
            }
        }
    },
};

export default {
    namespaced: true,
    state: defaultState,
    actions,
    mutations,
};
