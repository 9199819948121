import io from 'socket.io-client';
import { getAccessToken } from '../../auth';

import { APP_CONFIG } from '../..';

import {
    get as getResponseHandler,
} from './responseQueue';

import getEndpoint from '../endpoints';

class SocketManager {
    hasAuth = true;

    constructor(opts) {
        this.connectionAddress = opts.connectionAddress;
        this.connectionPath = opts.connectionPath;

        this.clientId = opts.clientId;
        this.getAccessToken = opts.getAccessToken ? opts.getAccessToken : () => '';
        this.hasAuth = opts.hasAuth ? opts.hasAuth : this.hasAuth;

        this.onResponse = opts.onResponse;

        this.socket = null;

        this.connect = this.connect.bind(this);
        this.emit = this.emit.bind(this);
    }

    connect() {
        return new Promise((resolve, reject) => {
            getEndpoint('yodaSocketUrl')
                .then((baseUrl) => {
                    const SOCKET = io(
                        `${baseUrl}/${this.connectionAddress}`,
                        {
                            withCredentials: true,
                            extraHeaders: {
                                'X-Client-Id': this.clientId,
                                ...(this.hasAuth && this.getAccessToken() && {
                                    'X-Access-Token': this.getAccessToken(),
                                }),
                            },
                            path: this.connectionPath,
                        },
                    );

                    SOCKET.on('disconnect', SocketManager.onDisconnect);
                    SOCKET.on('reconnect_failed', SocketManager.onReconnectFailed);
                    SOCKET.on('response', this.onResponse);
                    SOCKET.on('error', (err) => {
                        console.error('Socket Error', err);
                    });
                    SOCKET.on('connect', () => {
                        this.socket = SOCKET;
                        resolve();
                    });
                    SOCKET.on('connect_error', () => {
                        reject(new Error(`Failed to connect to ${this.connectionAddress}. Check your internet connection.`));
                    });
                });
        });
    }

    async emit(event, data) {
        if (!this.socket || this.socket.disconnected) {
            await this.connect();
        }

        this.socket.emit(event, data);
    }

    static onDisconnect(reason) {
        const serverDisconnect = /server disconnect/ig;
        if (!serverDisconnect.test(reason)) {
            console.error('Disconnected', reason);
        }
    }

    static onReconnectFailed() {
        console.error('Reconnet failed!');
    }
}

export const sessionSocket = new SocketManager({
    connectionAddress: 'session',
    connectionPath: '/yaas',
    clientId: APP_CONFIG.clientId,
    getAccessToken,
    onResponse: getResponseHandler,
});

export const logSocket = new SocketManager({
    connectionAddress: 'log',
    connectionPath: '/yaas',
    clientId: APP_CONFIG.clientId,
    getAccessToken,
    onResponse: getResponseHandler,
});

export const simulatorSocket = new SocketManager({
    connectionAddress: 'simulator',
    connectionPath: '/yaas',
    clientId: APP_CONFIG.simulatorClientId,
    getAccessToken,
    onResponse: getResponseHandler,
});

export const iotSocket = new SocketManager({
    connectionAddress: 'iot',
    connectionPath: '/yaas',
    clientId: APP_CONFIG.clientId,
    getAccessToken,
    onResponse: getResponseHandler,
});
