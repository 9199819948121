import Vue from 'vue';

import {
    mapState,
    mapActions,
} from 'vuex';

import App from './App.vue';
import router from './router';
import store from './store';

import { APP_CONFIG } from './utils';

import {
    setUp as setupUmami,
} from './analytics/umami';

import {
    setUp as setupIntercom,
    boot as bootIntercom,
    update as updateIntercom,
} from './analytics/intercom';

// import './registerServiceWorker';

import './assets/styles/index.css';

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),

    data() {
        return {
            analyticsEnabled: APP_CONFIG.analytics.enabled,
            canGetFileTree: this.$route.name !== 'signin',
        };
    },

    computed: {
        ...mapState('auth', [
            'sessionActive',
            'user',
        ]),
    },

    watch: {
        user(newUserValue, prevUserValue) {
            if (this.analyticsEnabled) {
                if (newUserValue !== null
                    && prevUserValue === null
                    && this.sessionActive) {
                    bootIntercom(newUserValue);
                }
            }
        },
    },

    created() {
        if (this.analyticsEnabled) {
            setupIntercom();
            setupUmami();
        }

        this.$router.afterEach(() => {
            if (this.analyticsEnabled) {
                if (this.sessionActive && window.intercomBootComplete) {
                    updateIntercom(this.user.email);
                }
            }
        });

        if (this.canGetFileTree) {
            this.getDocsFileTree();
        }
    },

    methods: {
        ...mapActions('docs', [
            'getDocsFileTree',
        ]),
    },
}).$mount('#app');
