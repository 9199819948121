import { iotSocket } from './socketManager';

import {
    queue as queueResponseHandler,
} from './responseQueue';

const IOT_EMIT_EVENTS = {
    GET_DEVICE_STATUS: 'GET_DEVICE_STATUS',
    SYNC_BINARY: 'SYNC_BINARY',
    WATCH_LOGS: 'WATCH_LOGS',
    END_WATCH_LOGS: 'END_WATCH_LOGS',
};

const generateEventId = () => `evt::session::${Date.now()}`;

export const getDeviceStatus = ({ sessionId }) => new Promise((resolve, reject) => {
    const eventId = generateEventId();

    const data = {
        eventId,
        sessionId,
    };

    const responseHandler = (res) => {
        if ((res.status >= 200 && res.status < 300)) {
            resolve(res.data);
        } else {
            reject(new Error(res.message));
        }
    };

    const queueFactoryFunc = () => queueResponseHandler(eventId, responseHandler);
    iotSocket
        .emit(IOT_EMIT_EVENTS.GET_DEVICE_STATUS, data)
        .then(queueFactoryFunc)
        .catch(reject);
});

export const syncBinary = ({ sessionId, buildSrc, cb }) => new Promise((resolve, reject) => {
    const eventId = generateEventId();

    const data = {
        eventId,
        sessionId,
        buildSrc,
    };

    const queueFactoryFunc = () => {
        queueResponseHandler(eventId, cb);
        resolve();
    };

    iotSocket
        .emit(IOT_EMIT_EVENTS.SYNC_BINARY, data)
        .then(queueFactoryFunc)
        .catch(reject);
});

export const watchLogs = ({ sessionId, cb }) => new Promise((resolve, reject) => {
    const eventId = generateEventId();

    const data = {
        eventId,
        sessionId,
    };

    const queueFactoryFunc = () => {
        queueResponseHandler(eventId, cb);
        resolve();
    };

    iotSocket
        .emit(IOT_EMIT_EVENTS.WATCH_LOGS, data)
        .then(queueFactoryFunc)
        .catch(reject);
});

export const endWatchLogs = ({ sessionId }) => {
    const eventId = generateEventId();

    const data = {
        sessionId,
        eventId,
    };

    const responseHandler = (res) => {
        if (!(res.status >= 200 && res.status < 300)) {
            throw new Error(res.message);
        }
    };

    const queueFactoryFunc = () => queueResponseHandler(eventId, responseHandler);
    iotSocket
        .emit(IOT_EMIT_EVENTS.END_WATCH_LOGS, data)
        .then(queueFactoryFunc)
        .catch(console.error);
};
