import { simulatorSocket } from './socketManager';

import {
    queue as queueResponseHandler,
} from './responseQueue';

const SIMULATOR_EMIT_EVENTS = {
    START: 'start',
    SUBMIT: 'submit',
    END: 'end',
};

const generateEventId = () => `evt::session::${Date.now()}`;

export const startSession = (params) => new Promise((resolve, reject) => {
    const {
        phoneNumber,
        cb,
    } = params;

    const eventId = generateEventId();
    const simulatorEventId = generateEventId();

    const data = {
        eventId,
        simulatorEventId,
        phoneNumber,
    };

    const responseHandler = (res) => {
        if ((res.status >= 200 && res.status < 300)) {
            queueResponseHandler(simulatorEventId, cb);
            resolve(res.message);
        } else {
            reject(new Error(res.message));
        }
    };

    const queueFactoryFunc = () => queueResponseHandler(eventId, responseHandler);
    simulatorSocket
        .emit(SIMULATOR_EMIT_EVENTS.START, data)
        .then(queueFactoryFunc)
        .catch(reject);
});

export const submit = (params) => new Promise((resolve, reject) => {
    const eventId = generateEventId();
    const data = {
        eventId,
        data: params,
    };

    const responseHandler = (res) => {
        if ((res.status >= 200 && res.status < 300)) {
            resolve(res.message);
        } else {
            reject(new Error(res.message));
        }
    };

    const queueFactoryFunc = () => queueResponseHandler(eventId, responseHandler);
    simulatorSocket
        .emit(SIMULATOR_EMIT_EVENTS.SUBMIT, data)
        .then(queueFactoryFunc)
        .catch(reject);
});

export const endSession = () => new Promise((resolve, reject) => {
    const eventId = generateEventId();
    const data = {
        eventId,
    };

    const responseHandler = (res) => {
        if ((res.status >= 200 && res.status < 300)) {
            resolve();
        } else {
            reject(new Error(res.message));
        }
    };

    const queueFactoryFunc = () => queueResponseHandler(eventId, responseHandler);
    simulatorSocket
        .emit(SIMULATOR_EMIT_EVENTS.END, data)
        .then(queueFactoryFunc)
        .catch(reject);
});
