const RESPONSE_QUEUE = {};

export const queue = (id, cb) => {
    RESPONSE_QUEUE[id] = cb;
};

export const get = (response) => {
    try {
        if (response.eventId !== undefined) {
            const cb = RESPONSE_QUEUE[response.eventId];

            if (cb !== undefined) {
                cb(response);
            } else {
                const error = new Error(`Could not find response handler for event: ${response.eventId}`);
                console.error(error);
            }
        }
    } catch (ex) {
        console.error(`Failed while getting response handler for response -> ${JSON.stringify(response)} ex -> ${ex}`);
    }
};
