<template>
    <div class="flex flex-row py-8 px-4 justify-center text-gray-700">
        <div
            v-if="isWorking"
            class="flex flex-col">
            <AppLoader class="h-8 mb-2 stroke-current" />
            <span>{{ message }}</span>
        </div>
        <span v-if="error">{{ error }}</span>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import AppLoader from '@/components/ui/AppLoader.vue';
import { isSignedIn } from '@/utils/auth';

export default {
    name: 'Signin',

    props: {
        code: {
            type: String,
            required: true,
        },
        state: {
            type: String,
            required: true,
        },
        authStateSecret: {
            type: String,
            required: true,
        },
        returnPath: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isWorking: false,
            message: 'Signing In...',
            error: null,
        };
    },

    created() {
        this.doSignin();
    },

    methods: {
        ...mapActions('auth', [
            'populateSession',
        ]),

        async doSignin() {
            try {
                if (this.state !== this.authStateSecret) {
                    this.error = 'Invalid Login';
                    return;
                }

                this.isWorking = true;
                if (!isSignedIn()) {
                    throw new Error('Failed to sign in :\'( Kindly Re-try.');
                }
                this.populateSession();
                window.location = this.returnPath;
            } catch (ex) {
                this.isWorking = false;
                this.error = ex.message || 'Failed... Kindly Re-try.';
            }
        },
    },

    components: {
        AppLoader,
    },
};
</script>
