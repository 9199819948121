var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        ' w-3/5': _vm.placement === 'header',
        'w-full': _vm.placement === 'landing',
    }},[_c('div',{staticClass:"relative"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.searchQuery),expression:"searchQuery",modifiers:{"trim":true}}],staticClass:"appearance-none rounded-md block w-full placeholder-gray-400 text-gray-700 focus:outline-none focus:shadow-outline-gray focus:shadow-md focus:z-10",class:{
                'p-2 pl-10 text-sm': _vm.placement === 'header',
                'p-3 pl-10 sm:text-sm sm:leading-5': _vm.placement === 'landing',
            },attrs:{"aria-label":"Search Term","name":"search","type":"text","required":"","autocomplete":"off","placeholder":"Search documentation..."},domProps:{"value":(_vm.searchQuery)},on:{"blur":[_vm.emptySearchResults,function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value.trim()}}}),_c('div',{staticClass:"absolute left-0 top-0 text-gray-600",class:{
                'p-2': _vm.placement === 'header',
                'p-3': _vm.placement === 'landing',
            }},[(!_vm.isSearching)?_c('searchSvg',{staticClass:"h-5 fill-current"}):_vm._e(),(_vm.isSearching)?_c('AppLoader',{staticClass:"h-5 stroke-current"}):_vm._e()],1)]),(_vm.hasResults)?_c('div',{staticClass:"absolute w-full overflow-hidden bg-white rounded-md shadow-md z-30 result-box-top"},[_c('div',{staticClass:"flex flex-col w-full h-full"},[(_vm.formattedDocs.length > 0)?_c('div',{staticClass:"flex flex-col"},_vm._l((_vm.formattedDocs),function(doc){return _c('div',{key:doc.path},[_c('router-link',{staticClass:"flex text-base flex-col py-1 px-3 bg-white border-b hover:bg-gray-200",attrs:{"to":`/docs/${doc.path}`}},[_c('span',{staticClass:"font-bold text-sm"},[_vm._v("Reference > "+_vm._s(doc.title))]),_c('span',{staticClass:"mt-1 truncate text-sm"},[_vm._v(_vm._s(doc.content))])])],1)}),0):_vm._e(),(_vm.formattedTutorials.length > 0)?_c('div',{staticClass:"flex flex-col"},_vm._l((_vm.formattedTutorials),function(tutorial){return _c('div',{key:tutorial._id},[_c('router-link',{staticClass:"flex flex-col text-sm py-1 px-3 bg-white border-b hover:bg-gray-200",attrs:{"to":`/tutorials/${tutorial._id}`}},[_c('span',{staticClass:"font-bold text-sm"},[_vm._v("Tutorial > "+_vm._s(tutorial.title))]),_c('span',{staticClass:"mt-1 truncate text-sm"},[_vm._v(_vm._s(tutorial.description))])])],1)}),0):_vm._e(),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex text-base flex-row-reverse py-1 px-3 bg-white border-w"},[_c('a',{attrs:{"href":"https://www.algolia.com/"}},[_c('powered-by-algolia')],1)])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }