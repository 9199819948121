import {
    getTutorials,
    getTutorial,
    rateTutorial,
    getTutorialFilesTree,
    getTutorialFile,
    updateTutorialStats,
    deleteTutorialStats,
} from '../../utils/api/http/tutorials';

import { generateMutations } from '../utils';

const defaultState = {
    isLoadingTutorials: false,
    isLoadingTutorial: false,
    isRatingTutorial: false,
    tutorials: [],
    tutorial: {},
    lesson: {},
};

const mutations = generateMutations(defaultState);

const getters = {
    spotlightTutorials(state) {
        const spotlightTutorials = state.tutorials
            .filter((t) => t.spotlight === undefined)
            .map((t) => ({
                title: t.title,
                slug: t.slug,
                artwork: t.artwork,
            }));
        return spotlightTutorials.splice(0, 2);
    },

    lessons(state) {
        const { tutorial } = state;
        if (Object.keys(tutorial).length === 0) {
            return [];
        }

        /* eslint-disable prefer-destructuring, no-underscore-dangle */
        if (tutorial.userData !== undefined) {
            const completedLessonIds = tutorial.userData.stat.completed;
            const runtime = tutorial.userData.runtime;
            const lessons = tutorial.lessons
                .filter((l) => l.runtime === runtime)
                .map((lesson) => {
                    if (completedLessonIds.indexOf(lesson._id) !== -1) {
                        return {
                            ...lesson,
                            ...{ completed: true },
                        };
                    }
                    return lesson;
                });
            return lessons;
        }

        const runtime = tutorial.runtimes[0].name;
        const lessons = tutorial.lessons
            .filter((l) => l.runtime === runtime);
        return lessons;
        /* eslint-enable prefer-destructuring, no-underscore-dangle */
    },
};

const actions = {
    async getTutorials({ commit, dispatch }, query = {}) {
        try {
            commit('setIsLoadingTutorials', true);
            const tutorials = await getTutorials(query);
            commit('setTutorials', tutorials);
            commit('setIsLoadingTutorials', false);
        } catch (ex) {
            if (ex.name === 'ExpiredToken') {
                await new Promise((resolve) => {
                    setTimeout(() => {
                        dispatch('getTutorials', query);
                        resolve();
                    }, 1000);
                });
            } else {
                throw ex;
            }
        }
    },

    async getTutorial({ commit, dispatch }, query) {
        try {
            commit('setIsLoadingTutorial', true);
            const tutorial = await getTutorial(query);
            commit('setTutorial', tutorial);
            commit('setIsLoadingTutorial', false);
        } catch (ex) {
            if (ex.name === 'ExpiredToken') {
                await new Promise((resolve) => {
                    setTimeout(() => {
                        dispatch('getTutorial', query);
                        resolve();
                    }, 1000);
                });
            } else {
                throw ex;
            }
        }
    },

    async rateTutorial({ commit }, data) {
        try {
            commit('setIsRatingTutorial', true);
            await rateTutorial(data);
            commit('setIsRatingTutorial', false);
        } catch (ex) {
            console.error(ex);
            commit('setIsRatingTutorial', false);
        }
    },

    async getTutorialFilesTree(_, query) {
        const tutorialFilesTree = await getTutorialFilesTree(query);
        return tutorialFilesTree;
    },

    async getTutorialFile(_, query) {
        const tutorialFile = await getTutorialFile(query);
        return tutorialFile;
    },

    async updateTutorialStats({ commit, rootState }, data) {
        await updateTutorialStats(data);
        const { tutorial } = rootState.tutorials;
        const updatedTutorial = {
            ...tutorial,
            ...{ userData: data.data },
        };
        commit('setTutorial', updatedTutorial);
    },

    async deleteTutorialStats({ commit, rootState }, data) {
        await deleteTutorialStats(data);
        const { tutorial } = rootState.tutorials;
        const { userData, ...rest } = tutorial;
        commit('setTutorial', rest);
    },
};

export default {
    namespaced: true,
    state: defaultState,
    actions,
    getters,
    mutations,
};
