import invokeImpl from './invoke';

import {
    defaultQueryParams,
    formatQueryParams,
} from '../..';

import getEndpoint from '../endpoints';

const invoke = async (method, path, data, options = {}) => getEndpoint('yodaApiUrl')
    .then((baseUrl) => invokeImpl(method, path, data, {
        ...options,
        baseUrl,
    }));

export const getTutorials = async (query = defaultQueryParams) => invoke(
    'GET',
    `/courses${formatQueryParams(query)}`,
);

export const getTutorial = async ({ tutorialSlug }) => invoke('GET', `/courses/${tutorialSlug}`);

export const rateTutorial = async ({ tutorialId, data }) => invoke(
    'POST',
    `/courses/${tutorialId}/rate`,
    data,
);

export const getTutorialFilesTree = async ({ tutorialId, runtime }) => invoke(
    'GET',
    `/courses/${tutorialId}/${runtime}/files/tree`,
);

export const getTutorialFile = async ({ tutorialId, runtime, fileName }) => invoke(
    'GET',
    `/courses/${tutorialId}/${runtime}/files/file?name=${fileName}`,
);

export const updateTutorialStats = async ({ tutorialId, data }) => invoke(
    'POST',
    `/courses/${tutorialId}/stats`,
    data,
);

export const deleteTutorialStats = async ({ tutorialId }) => invoke(
    'DELETE',
    `/courses/${tutorialId}/stats`,
);
