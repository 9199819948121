/* eslint-disable */

import { APP_CONFIG } from '../utils';

export function setUp() {
    const src = APP_CONFIG.analytics.umami.script;
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;
    script.dataset.cache = true;
    script.dataset.doNotTrack = true;
    script.dataset.websiteId = APP_CONFIG.analytics.umami.id;
    document.body.appendChild(script);
}