<template>
    <div class="relative w-screen flex bg-orange-gradient lg:bg-none mt-20 lg:mt-48">
        <div class="hidden lg:block w-screen-50 absolute h-full bg-orange-gradient rounded-r-5xl">
            <span class="absolute h-56 bottom-8 -right-56">
                <dotsSvg class="h-full" />
            </span>
        </div>
        <div class="w-full sm:w-11/12 lg:w-11/12 xl:w-10/12 relative mx-auto z-10">
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 px-6 py-12 lg:py-16">
                <div class="text-center md:col-span-2 lg:col-auto lg:text-left flex flex-col justify-center lg:-mt-12 md:pr-20">
                    <h1 class="text-white text-2xl mb-4 font-semibold">Interactive Tutorials</h1>
                    <p class="text-white">Learn how to build with our APIs at your own pace through interactive tutorials.</p>
                </div>
                <template v-if="isLoadingTutorials">
                    <div
                        v-for="(_, idx) in [1,2]"
                        :key="idx"
                        class="rounded-lg shadow-xl flex flex-row items-center h-64 justify-center text-gray-400 bg-white">
                        <AppLoader class="h-16 stroke-current" />
                    </div>
                </template>
                <template v-if="!isLoadingTutorials && spotlightTutorials.length > 0">
                    <div
                        v-for="(tutorial, idx) in spotlightTutorials"
                        :key="idx"
                        class="rounded-lg shadow-xl overflow-hidden bg-white">
                        <router-link
                            :to="`/tutorials/${tutorial.slug}`">
                            <div class="flex flex-row items-center h-56 justify-center py-8">
                                <img class="block h-full -mt-4" :src="tutorial.artwork" />
                            </div>
                            <div class="px-4 text-gray-600 -mt-4">
                                <div class="border-t border-gray-200 py-4 flex flex-col">
                                    <span class="text-base text-center">{{ tutorial.title }}</span>
                                    <span class="flex flex-row text-center mt-2 text-green-500 self-center">
                                        <span class="text-sm">Learn More</span>
                                        <arrowRightSvg class="ml-1 h-6 fill-current" />
                                    </span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapActions,
    mapGetters,
    mapState,
} from 'vuex';

import AppLoader from '@/components/ui/AppLoader.vue';

export default {
    name: 'Spotlight',

    created() {
        this.doGetTutorials();
    },

    computed: {
        ...mapState('tutorials', [
            'isLoadingTutorials',
        ]),

        ...mapGetters('tutorials', [
            'spotlightTutorials',
        ]),
    },

    methods: {
        ...mapActions('tutorials', [
            'getTutorials',
        ]),

        async doGetTutorials() {
            try {
                await this.getTutorials();
            } catch (ex) {
                console.error(ex);
            }
        },
    },

    components: {
        AppLoader,
        arrowRightSvg: () => import('@/assets/img/arrowRight.svg'),
        dotsSvg: () => import('@/assets/img/dots.svg'),
    },
};
</script>
