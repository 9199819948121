<template>
    <div class="w-full flex flex-col items-center justify-center relative mt-32 px-4">
        <div class="w-full sm:w-3/5 md:w-2/5 text-center relative z-10">
            <h1 class="text-2xl sm:text-3xl mb-4 font-semibold">Page Not Found</h1>
            <p class="text-lg sm:text-xl">Oops! The page you were looking for could not be found. You can <router-link to="/" class="text-orange-500 hover:text-orange-600">go to the landing page</router-link> or follow any link on the sidebar menu.</p>
        </div>
        <FourOhFourSvg class="four-oh-four z-0 absolute top-32 sm:top-16 md:top-8" />
    </div>
</template>

<script>
import FourOhFourSvg from './FourOhFourSvg.vue';

export default {
    name: 'PageNotFound',

    components: {
        FourOhFourSvg,
    },
};
</script>

<style lang="postcss">
.four-oh-four {
    height: 10rem;
}

@screen sm {
    .four-oh-four {
        height: 18rem;
    }
}

@screen md {
    .four-oh-four {
        height: 24rem;
    }
}
</style>
