<template>
    <div
        :class="{
            ' w-3/5': placement === 'header',
            'w-full': placement === 'landing',
        }">
        <div class="relative">
            <input
                aria-label="Search Term"
                name="search"
                type="text"
                required
                v-model.trim="searchQuery"
                v-on:blur="emptySearchResults"
                autocomplete="off"
                class="appearance-none rounded-md block w-full placeholder-gray-400 text-gray-700 focus:outline-none focus:shadow-outline-gray focus:shadow-md focus:z-10"
                :class="{
                    'p-2 pl-10 text-sm': placement === 'header',
                    'p-3 pl-10 sm:text-sm sm:leading-5': placement === 'landing',
                }"
                placeholder="Search documentation..." />
            <div
                class="absolute left-0 top-0 text-gray-600"
                :class="{
                    'p-2': placement === 'header',
                    'p-3': placement === 'landing',
                }">
                <searchSvg v-if="!isSearching" class="h-5 fill-current" />
                <AppLoader v-if="isSearching" class="h-5 stroke-current" />
            </div>
        </div>
        <div
            v-if="hasResults"
            class="absolute w-full overflow-hidden bg-white rounded-md shadow-md z-30 result-box-top">
            <div class="flex flex-col w-full h-full">
                <div
                    v-if="formattedDocs.length > 0"
                    class="flex flex-col">
                    <div
                        v-for="doc in formattedDocs"
                        :key="doc.path">
                        <router-link
                            :to="`/docs/${doc.path}`"
                            class="flex text-base flex-col py-1 px-3 bg-white border-b hover:bg-gray-200">
                            <span class="font-bold text-sm">Reference > {{ doc.title }}</span>
                            <span class="mt-1 truncate text-sm">{{ doc.content }}</span>
                        </router-link>
                    </div>
                </div>
                <div
                    v-if="formattedTutorials.length > 0"
                    class="flex flex-col">
                    <div
                        v-for="tutorial in formattedTutorials"
                        :key="tutorial._id">
                        <router-link
                            :to="`/tutorials/${tutorial._id}`"
                            class="flex flex-col text-sm py-1 px-3 bg-white border-b hover:bg-gray-200">
                            <span class="font-bold text-sm">Tutorial > {{ tutorial.title }}</span>
                            <span class="mt-1 truncate text-sm">{{ tutorial.description }}</span>
                        </router-link>
                    </div>
                </div>
                <div class="flex flex-col">
                    <div class="flex text-base flex-row-reverse py-1 px-3 bg-white border-w">
                        <a href="https://www.algolia.com/">
                            <powered-by-algolia />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapState,
    mapActions,
    mapMutations,
} from 'vuex';

import debounce from 'lodash.debounce';

import { titleCase } from '../../utils';

export default {
    name: 'AppSearch',

    props: {
        placement: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            searchQuery: '',
        };
    },

    beforeDestroy() {
        this.emptySearchResults();
    },

    computed: {
        ...mapState('search', [
            'isSearching',
            'docs',
            'tutorials',
        ]),

        hasResults() {
            return this.searchQuery.length && (this.tutorials.length > 0 || this.docs.length > 0);
        },

        formattedDocs() {
            const docs = this.docs.map((doc) => {
                const { name, content } = doc;
                let title = name.charAt(0).toUpperCase() + name.slice(1);
                title = title
                    .replace(/\.md$/, '')
                    .replace('_', ' ')
                    .replace(/\//g, ' > ');
                return {
                    path: name.replace(/\.md$/, ''),
                    title: titleCase(title),
                    content: content.split(/\n/g)[2],
                };
            });
            return docs.slice(0, 5);
        },

        formattedTutorials() {
            const tutorials = this.tutorials.map((tutorial) => {
                const { _id, title, description } = tutorial;
                return { _id, title, description };
            });
            return tutorials.slice(0, 5);
        },
    },

    watch: {
        searchQuery() {
            this.doSearch();
        },

        $route() {
            this.searchQuery = '';
        },
    },

    methods: {
        ...mapActions('search', [
            'search',
        ]),

        ...mapMutations('search', [
            'setTutorials',
            'setDocs',
        ]),

        emptySearchResults() {
            setTimeout(() => {
                this.setTutorials([]);
                this.setDocs([]);
            }, 300);
        },

        doSearch: debounce(function fx() {
            const { searchQuery } = this;
            if (searchQuery.length > 0) {
                this.search({ q: searchQuery });
            } else {
                this.emptySearchResults();
            }
        }, 250, { trailing: true }),
    },

    components: {
        searchSvg: () => import('@/assets/img/search.svg'),
        AppLoader: () => import('./AppLoader.vue'),
        PoweredByAlgolia: () => import('@/assets/img/powered_by_algolia.svg'),
    },
};
</script>

<style lang="postcss" scoped>
.result-box-top {
    top: 110%;
}
</style>
