const capitalize = (value) => {
    const source = value;
    return source.charAt(0).toUpperCase() + source.slice(1);
};

export const generateMutations = (state) => {
    const mutations = Object
        .keys(state)
        .reduce((base, next) => ({
            ...{
                // eslint-disable-next-line no-shadow
                [`set${capitalize(next)}`](state, data) {
                    state[next] = data;
                },
            },
            ...base,
        }), {});

    return mutations;
};

export { generateMutations as default };
