<template>
    <div class="flex products-banner mx-auto m-auto -mt-32">
        <div class="w-full inner-banner sm:w-11/12 lg:w-11/12 xl:w-10/12 mx-auto mt-0 z-20">
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 px-6">
                <div>
                    <ninjaOneSvg class="ninja-svg h-32 lg:h-56 mx-auto lg:mx-0 transform -translate-y-12 md:-translate-y-24 -translate-x-8 md:-translate-x-24 md:scale-75 relative z-20" />

                </div>
                <div
                    v-for="(product, idx) in shownProducts"
                    :key="idx"
                    class="rounded-lg shadow-xl overflow-hidden">
                    <div
                        class="relative flex flex-row items-center justify-center py-8 overflow-hidden"
                        :class="getProductBackgroundColor(idx)">
                        <abstractPatternSvg class="w-full absolute z-0" />
                        <div class="flex flex-row items-center justify-center h-20 w-20 bg-white rounded relative z-1">
                            <component class="h-16 fill-current text-white -mt-2" v-bind:is="product.icon" />
                        </div>
                    </div>
                    <div class="px-4 text-gray-600 bg-white">
                        <div class="border-b border-gray-200 text-center py-4">
                            <router-link
                                :to="product.route"
                                element="span"
                                class="text-lg font-semibold transition duration-200 ease-in-out hover:text-gray-900">
                                {{ product.title }}
                            </router-link>
                        </div>
                        <div class="flex flex-row sm:flex-col md:flex-row justify-between py-4">
                            <router-link
                                v-for="(link, idx) in product.links"
                                :key="idx"
                                :to="link.to"
                                class="flex flex-row items-center transition duration-200 ease-in-out hover:text-gray-900 sm:mb"
                                :class="{
                                    'sm:mb-2': idx === 0
                                }">
                                <span class="flex flex-row h-10 w-10 mr-2 rounded-lg items-center justify-center bg-gray-200">
                                    <component class="h-4 fill-current text-blue-300" v-bind:is="link.icon" />
                                </span>
                                <span class="text-sm md:text-base">{{ link.title }}</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapState,
} from 'vuex';

import ninjaOneSvg from '@/components/landing/elements/ninjaOnetSvg.vue';

export default {
    name: 'Products',

    data() {
        return {
            products: [
                {
                    title: 'SMS',
                    icon: 'smsSvg',
                    route: '/docs/sms/overview',
                    links: [{
                        to: '/tutorials?products=sms',
                        title: 'Tutorials',
                        icon: 'schoolSvg',
                    }, {
                        to: '/docs/sms/overview',
                        title: 'API Reference',
                        icon: 'bookSvg',
                    }],
                },
                {
                    title: 'USSD',
                    icon: 'ussdSvg',
                    route: '/docs/ussd/overview',
                    links: [{
                        to: '/tutorials?products=ussd',
                        title: 'Tutorials',
                        icon: 'schoolSvg',
                    }, {
                        to: '/docs/ussd/overview',
                        title: 'API Reference',
                        icon: 'bookSvg',
                    }],
                },
                {
                    title: 'Voice',
                    icon: 'voiceSvg',
                    route: '/docs/voice/overview',
                    links: [{
                        to: '/tutorials?products=voice',
                        title: 'Tutorials',
                        icon: 'schoolSvg',
                    }, {
                        to: '/docs/voice/overview',
                        title: 'API Reference',
                        icon: 'bookSvg',
                    }],
                },
                // {
                //     title: 'Payments',
                //     icon: 'paymentsSvg',
                //     route: '/docs/payments/overview',
                //     links: [{
                //         to: '/tutorials?product=payments',
                //         title: 'Tutorials',
                //         icon: 'schoolSvg',
                //     }, {
                //         to: '/docs/payments/overview',
                //         title: 'API Reference',
                //         icon: 'bookSvg',
                //     }],
                // },
                {
                    title: 'Airtime',
                    icon: 'airtimeSvg',
                    route: '/docs/airtime/overview',
                    links: [{
                        to: '/tutorials?products=airtime',
                        title: 'Tutorials',
                        icon: 'schoolSvg',
                    }, {
                        to: '/docs/airtime/overview',
                        title: 'API Reference',
                        icon: 'bookSvg',
                    }],
                },
                // {
                //     title: 'IoT',
                //     icon: 'iotSvg',
                //     route: '/docs/iot/overview',
                //     links: [{
                //         to: '/tutorials?products=iot',
                //         title: 'Tutorials',
                //         icon: 'schoolSvg',
                //     }, {
                //         to: '/docs/iot/overview',
                //         title: 'API Reference',
                //         icon: 'bookSvg',
                //     }],
                // },
                {
                    title: 'Mobile Data',
                    icon: 'iotSvg',
                    route: '/docs/data/overview',
                    links: [{
                        to: '/tutorials?products=data',
                        title: 'Tutorials',
                        icon: 'schoolSvg',
                    }, {
                        to: '/docs/data/overview',
                        title: 'API Reference',
                        icon: 'bookSvg',
                    }],
                },
                {
                    preview: true,
                    title: 'Chat',
                    icon: 'smsSvg',
                    route: '/docs/chat/overview',
                    links: [{
                        to: '/tutorials?product=chat',
                        title: 'Tutorials',
                        icon: 'schoolSvg',
                    }, {
                        to: '/docs/chat/overview',
                        title: 'API Reference',
                        icon: 'bookSvg',
                    }],
                },
                {
                    preview: true,
                    title: 'Insights',
                    icon: 'iotSvg',
                    route: '/docs/insights/overview',
                    links: [{
                        to: '/tutorials?product=insights',
                        title: 'Tutorials',
                        icon: 'schoolSvg',
                    }, {
                        to: '/docs/insights/overview',
                        title: 'API Reference',
                        icon: 'bookSvg',
                    }],
                },
            ],
        };
    },

    computed: {
        ...mapState([
            'isPreview',
        ]),

        shownProducts() {
            const prods = this.products.filter((p) => !p.preview || this.isPreview);
            return prods;
        },
    },

    methods: {
        getProductBackgroundColor(idx) {
            const colorMap = [
                'bg-green-gradient',
                'bg-red-gradient',
                'bg-orange-gradient',
            ];
            const colorMapLength = colorMap.length;

            const color = {};

            if (idx <= colorMapLength - 1) {
                const colorIndex = idx;
                color[colorMap[colorIndex]] = true;
            } else {
                const colorIndex = idx - (colorMapLength * (Math.floor(idx / colorMapLength)));
                color[colorMap[colorIndex]] = true;
            }

            return color;
        },
    },

    components: {
        abstractPatternSvg: () => import('@/assets/img/abstractPattern.svg'),
        airtimeSvg: () => import('@/assets/img/airtime.svg'),
        schoolSvg: () => import('@/assets/img/school.svg'),
        bookSvg: () => import('@/assets/img/book.svg'),
        iotSvg: () => import('@/assets/img/iot.svg'),
        paymentsSvg: () => import('@/assets/img/payments.svg'),
        smsSvg: () => import('@/assets/img/sms.svg'),
        ussdSvg: () => import('@/assets/img/ussd.svg'),
        voiceSvg: () => import('@/assets/img/voice.svg'),
        ninjaOneSvg,
    },
};
</script>
<style lang="postcss" scoped>
.products-banner{
    margin-top: -8rem;
    top: 20rem;
}

.inner-banner {
    background-color: none;

}

@screen sm {
    .products-banner{
        margin-top: -9rem;
    }
    .ninja-svg {
        margin-left: 0;
        margin-top: 0;
    }
}

@screen md {
    .ninja-svg {
        height: 15rem;
        --transform-translate-y: -2rem;
        --transform-translate-x: -4rem;
        margin-left: 4rem;
        margin-top: 2rem;
    }
}

@screen lg {
    .ninja-svg {
        height: 13rem;
        --transform-translate-y: -1rem;
        --transform-translate-x: -3rem;
        top: -0.5rem;
        margin-left: 4rem;
        margin-top: 2rem;
    }
}

@screen xl {
    .products-banner {
        margin-top: -10rem;
    }
    .ninja-svg {
        height: 14rem;
        --transform-translate-y: -8rem;
        --transform-translate-x: -8rem;
        top: -0.5rem;
        margin-left: 4rem;
        margin-top: 2rem;
    }
}
</style>
