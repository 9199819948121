<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 561.17 570.93"><defs><linearGradient id="linear-gradient" x1="87.51" y1="194.04" x2="438.04" y2="194.04" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#ffd768"/><stop offset="1" stop-color="#ed9314"/></linearGradient><linearGradient id="linear-gradient-2" x1="31.3" y1="253.27" x2="561.17" y2="253.27" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#ee9515"/><stop offset="1" stop-color="#ffd96d"/></linearGradient></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path fill="url(#linear-gradient)" d="M438.04 227.67l-31.29 64.39L87.51 160.41l31.3-64.39 319.23 131.65z"/><path class="cls-2" d="M156.04 506.55l-31.29 64.38L0 64.39 31.3 0l124.74 506.55zM561.17 218.52l-31.29 64.39-405.13 288.02 31.29-64.38 405.13-288.03z"/><path d="M31.3 0l529.87 218.52L156 506.55zm87.5 96L194 401.2l244-173.53L118.8 96z" fill="url(#linear-gradient-2)"/></g></g></svg>
</template>

<script>
export default {
    name: 'triangleSvg',
};
</script>

<style scoped>
    .cls-2{fill:#ea8c11}
</style>
