import invokeImpl from './invoke';

import {
    formatQueryParams,
} from '../..';

import getEndpoint from '../endpoints';

const invoke = async (method, path, data, options = {}) => getEndpoint('yodaApiUrl')
    .then((baseUrl) => invokeImpl(method, path, data, {
        ...options,
        baseUrl,
    }));

export const search = async (query) => invoke(
    'GET',
    `/search${formatQueryParams(query)}`,
);

export { search as default };
