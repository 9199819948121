/* eslint-disable */

import { APP_CONFIG } from '../utils';

export function setUp() {
    var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;
    s.src='https://widget.intercom.io/widget/' + APP_CONFIG.analytics.intercom.appId;
    var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}

    window.intercomBootComplete = false;
    window.intercomSettings = {
        app_id: APP_CONFIG.analytics.intercom.appId,
    };
}

export function update(userEmail) {
    window.Intercom('update', {
        email: userEmail,
    });
}

export function boot(user) {
    const params = {
        user_id: user.id,
        app_id: APP_CONFIG.analytics.intercom.appId,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        created_at: user.created_at,
    };
    if (user.intercom) { params.user_hash = user.intercom.user_hash; }
    window.Intercom('boot', params);
    window.intercomBootComplete = true;
}
