import Vue from 'vue';
import Vuex from 'vuex';

import { EventBus } from '../utils';

import auth from './modules/auth';
import docs from './modules/docs';
import tutorials from './modules/tutorials';
import search from './modules/search';
import project from './modules/project';
import simulator from './modules/simulator';
import iotDevice from './modules/iotDevice';

import { generateMutations } from './utils';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const defaultState = {
    isMobile: window.matchMedia('(max-width: 640px)').matches,
    isPreview: new URLSearchParams(window.location.search).get('preview') != null,
};

const mutations = generateMutations(defaultState);

const store = new Vuex.Store({
    state: defaultState,
    mutations,
    modules: {
        auth,
        docs,
        tutorials,
        search,
        project,
        simulator,
        iotDevice,
    },
    strict: debug,
});

EventBus.$on('expiredToken', () => {
    store.dispatch('auth/expireSession');
});

export default store;
