import { APP_CONFIG } from '..';

import invoke from './http/invoke';

const ENDPOINTS = {};

let initialized = false;
let initializing = false;

function pingEndpoint(region, baseUrl, path) {
    return new Promise((resolve, reject) => {
        const startTime = performance.now();
        invoke('GET', path, null, { baseUrl })
            .then(() => {
                const stopTime = performance.now();
                const delay = stopTime - startTime;
                resolve({ region, delay });
            })
            .catch(reject);
    });
}

async function getFastestRegion() {
    const pingTests = [];
    const testCount = 2;

    for (let index = 0; index < testCount; index += 1) {
        APP_CONFIG.yoda.regions.forEach((r) => pingTests.push(pingEndpoint(r.name, r.apiUrl, '/ping')));
    }

    const pingResults = await Promise.all(pingTests);

    const pingResultsGrouped = pingResults
        .reduce((base, next) => {
            const { region } = next;
            // eslint-disable-next-line no-param-reassign
            if (!base[region]) base[region] = [];
            base[region].push(next.delay);
            return base;
        }, {});

    const pingResultsAverage = Object.entries(pingResultsGrouped)
        .reduce((base, [region, pingTimes]) => {
            if (!base[region]) {
                const totalPingTests = pingTimes.length;
                const totalPingTimes = pingTimes.reduce((a, b) => a + b, 0);
                const averagePingTime = totalPingTimes / totalPingTests;
                // eslint-disable-next-line no-param-reassign
                base[region] = averagePingTime;
            }
            return base;
        }, {});

    const pingResultsSorted = Object.entries(pingResultsAverage).sort((a, b) => {
        const [, aDelay] = a;
        const [, bDelay] = b;
        if (aDelay < bDelay) return -1;
        if (aDelay > bDelay) return 1;
        return 0;
    });

    const fastestEndpoint = pingResultsSorted[0];
    const [region] = fastestEndpoint;

    return region;
}

const setDefaultRegionEndpoints = () => {
    const yodaRegion = APP_CONFIG.yoda.regions.find((r) => r.name === APP_CONFIG.defaultRegion);
    const docsRegion = APP_CONFIG.docs.regions.find((r) => r.name === APP_CONFIG.defaultRegion);

    ENDPOINTS.yodaApiUrl = yodaRegion.apiUrl;
    ENDPOINTS.yodaSocketUrl = yodaRegion.socketUrl;
    ENDPOINTS.yodaDocsUrl = docsRegion.apiUrl;
    ENDPOINTS.yodaExecUrl = APP_CONFIG.piston.baseUrl;
};

export const initializeEndpoints = async () => {
    try {
        if (initializing) {
            setDefaultRegionEndpoints();
            return;
        }

        initializing = true;
        const fastestRegion = await getFastestRegion();

        const yodaRegion = APP_CONFIG.yoda.regions.find((r) => r.name === fastestRegion);
        const docsRegion = APP_CONFIG.docs.regions.find((r) => r.name === fastestRegion);

        ENDPOINTS.yodaApiUrl = yodaRegion.apiUrl;
        ENDPOINTS.yodaSocketUrl = yodaRegion.socketUrl;
        ENDPOINTS.yodaDocsUrl = docsRegion.apiUrl;
        ENDPOINTS.yodaExecUrl = APP_CONFIG.piston.baseUrl;

        initializing = false;
        initialized = true;
    } catch (ex) {
        setDefaultRegionEndpoints();
        initializing = false;
        initialized = true;
    }
};

export default async function getEndpoint(kind, useDefaultEndpoint = false) {
    if (useDefaultEndpoint) {
        setDefaultRegionEndpoints();
        return ENDPOINTS[kind];
    }

    if (!initialized) await initializeEndpoints();
    return ENDPOINTS[kind];
}
