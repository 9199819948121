<template>
    <div class="antialiased">
        <router-view/>
        <cookie-consent />
    </div>
</template>

<script>
import CookieConsent from '@/components/landing/CookiesConsent.vue';

export default {
    name: 'App',
    components: {
        CookieConsent,
    },
};
</script>
