import jwtDecode from 'jwt-decode';

import {
    setCookie,
    getCookie,
    deleteCookie,
    APP_CONFIG,
} from '.';

const COOKIES = {
    TOKEN: APP_CONFIG.cookies.accessToken,
    SESSION: APP_CONFIG.cookies.yodaSession,
};

export const setAccessToken = (token) => setCookie(COOKIES.TOKEN, token);
export const getAccessToken = () => getCookie(COOKIES.TOKEN);
export const deleteAccessToken = () => deleteCookie(COOKIES.TOKEN);

export const setSessionId = (sessionId) => setCookie(COOKIES.SESSION, sessionId);
export const getSessionId = () => getCookie(COOKIES.SESSION);
export const deleteSessionId = () => deleteCookie(COOKIES.SESSION);

export const isSignedIn = () => {
    try {
        const user = jwtDecode(getAccessToken());
        return user.email.length > 0;
    } catch (ex) {
        return false;
    }
};

export const getUser = () => jwtDecode(getAccessToken());
