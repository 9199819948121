import {
    getDocsFileTree,
    getDocsFile,
} from '../../utils/api/http/docs';

import { generateMutations } from '../utils';

const MAX_DOCS_FILE_CACHE = 5;

const defaultState = {
    isLoadingFileTree: false,
    isLoadingDoc: false,
    docsFileTree: [],
    docsFileCache: {
        files: {},
        orderedFileNames: [],
    },
    activeDocsFile: {},
    defaultRuntime: 'curl',
    selectedRuntime: null,
};

const mutations = generateMutations(defaultState);

const actions = {
    async getDocsFileTree({ commit }) {
        commit('setIsLoadingFileTree', true);
        const docsFileTree = await getDocsFileTree();
        commit('setDocsFileTree', docsFileTree);
        commit('setIsLoadingFileTree', false);
    },

    async getDocsFile({ commit, state }, { fileName }) {
        let docsFile = state.docsFileCache.files[fileName];
        if (docsFile) {
            commit('setActiveDocsFile', docsFile);
        } else {
            commit('setIsLoadingDoc', true);

            docsFile = await getDocsFile(fileName);
            commit('setActiveDocsFile', docsFile);

            commit('setIsLoadingDoc', false);

            let workingDocsFileCache = state.docsFileCache;
            if (workingDocsFileCache.orderedFileNames.length === MAX_DOCS_FILE_CACHE) {
                const oldestCacheEntry = workingDocsFileCache.orderedFileNames[0];

                const mutableDocsFileCache = JSON.parse(JSON.stringify(workingDocsFileCache));

                delete mutableDocsFileCache.files[oldestCacheEntry];
                mutableDocsFileCache.orderedFileNames.splice(0, 1);

                workingDocsFileCache = mutableDocsFileCache;
            }

            const docsFileCache = {
                files: {
                    ...workingDocsFileCache.files,
                    [fileName]: docsFile,
                },
                orderedFileNames: [
                    ...workingDocsFileCache.orderedFileNames,
                    fileName,
                ],
            };

            commit('setDocsFileCache', docsFileCache);
        }
    },
};

export default {
    namespaced: true,
    state: defaultState,
    actions,
    mutations,
};
