import gsap from 'gsap';

export default {
    methods: {
        playAnimations() {
            const isSmall = window.innerWidth < 1100;
            if (!isSmall) {
                const masterTimeline = gsap.timeline();
                masterTimeline
                    .add(this.landingIntroTL())
                    .add(this.landingFloatTL())
                    .play();
            }
        },

        landingIntroTL() {
            const landingIntroTimeline = gsap.timeline();
            landingIntroTimeline
                .to(document.querySelector('.ninja-svg'), {
                    duration: 2,
                    translateX: '0rem',
                    translateY: '-4rem',
                    scale: 1,
                    ease: 'power4.out',
                })
                .to(document.querySelector('.globe-svg'), {
                    duration: 4,
                    translateY: '-8rem',
                    opacity: 1,
                    ease: 'out',
                }, '<')
                .to(document.querySelector('.thunder-left-svg'), {
                    duration: 4,
                    translateY: '0rem',
                    translateX: '0rem',
                    scale: 1,
                    opacity: 1,
                    ease: 'out',
                }, '<')
                .to(document.querySelector('.triangle-left-svg'), {
                    duration: 3,
                    translateY: '0rem',
                    translateX: '0rem',
                    opacity: 1,
                    ease: 'power4.out',
                }, '<')
                .to(document.querySelector('.triangle-right-svg'), {
                    duration: 4,
                    translateY: '0rem',
                    translateX: '0rem',
                    scale: 1,
                    opacity: 1,
                    ease: 'out',
                }, '<')
                .to(document.querySelector('.thunder-right-svg'), {
                    duration: 4,
                    translateY: '0rem',
                    translateX: '0rem',
                    scale: 1,
                    opacity: 1,
                    ease: 'out',
                }, '<')
                .to(document.querySelector('.cube-svg'), {
                    duration: 3,
                    translateY: '0rem',
                    translateX: '-5rem',
                    scale: 0.9,
                    opacity: 1,
                    ease: 'out',
                }, '<')
                .to(document.querySelector('.code-cube-svg'), {
                    duration: 2,
                    translateY: '0rem',
                    translateX: '0rem',
                    ease: 'out',
                }, '<');
            return landingIntroTimeline;
        },

        landingFloatTL() {
            const landingFloatTimeline = gsap.timeline();
            landingFloatTimeline
                .to(document.querySelector('.globe-svg'), {
                    duration: 4,
                    yoyo: true,
                    yoyoEase: true,
                    repeat: -1,
                    translateY: '-7.5rem',
                    ease: 'power1.easeOut',
                })
                .to(document.querySelector('.thunder-left-svg'), {
                    duration: 4,
                    yoyo: true,
                    yoyoEase: true,
                    repeat: -1,
                    translateY: '1rem',
                    ease: 'power1.easeOut',
                }, '<')
                .to(document.querySelector('.triangle-right-svg'), {
                    duration: 4,
                    yoyo: true,
                    yoyoEase: true,
                    repeat: -1,
                    translateY: '-1rem',
                    ease: 'power1.easeOut',
                }, '<');
            return landingFloatTimeline;
        },
    },
};
