<template>
    <div class="h-screen w-screen">
        <AppHeader v-if="!withoutHeader" :withSearchBar="true" />

        <AppSidebar v-if="!withoutSidebar" />

        <div
            class="h-full"
            :class="{
                'pt-16': !withoutHeader,
                'sidebar-pl': !withoutSidebar,
            }">
            <router-view />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppShell',

    props: {
        withoutHeader: {
            type: Boolean,
            default: false,
        },

        withoutSidebar: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        AppHeader: () => import('@/components/ui/AppHeader.vue'),
        AppSidebar: () => import('@/components/ui/AppSidebar.vue'),
    },
};
</script>
