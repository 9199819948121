import Vue from 'vue';
import VueRouter from 'vue-router';

import AppShell from '../components/ui/AppShell.vue';
import PageNotFound from '../components/pageNotFound/PageNotFound.vue';

import Signin from '../views/Signin.vue';
import Home from '../views/Home.vue';

import {
    APP_CONFIG,
    getCookie,
    deleteCookie,
} from '../utils';

Vue.use(VueRouter);

const shellNoHeaderRoutes = ['home'];
const shellNoSidebarRoutes = ['home', 'lesson'];

const routes = [
    {
        path: '/auth/login',
        component: Signin,
        props(route) {
            const returnPath = getCookie(APP_CONFIG.cookies.returnPath);
            const authStateSecret = getCookie(APP_CONFIG.cookies.authStateSecret);

            deleteCookie(APP_CONFIG.cookies.returnPath);
            deleteCookie(APP_CONFIG.cookies.authStateSecret);

            return {
                code: route.query.code,
                state: route.query.state,
                returnPath,
                authStateSecret,
            };
        },
        name: 'signin',
    },
    {
        path: '/',
        component: AppShell,
        props(route) {
            return {
                withoutHeader: shellNoHeaderRoutes.includes(route.name),
                withoutSidebar: shellNoSidebarRoutes.includes(route.name),
            };
        },
        children: [{
            path: '/',
            component: Home,
            name: 'home',
        }],
    },
    {
        path: '/docs',
        redirect: '/docs/introduction',
        component: AppShell,
        children: [{
            path: '/docs/:fileName+',
            component: () => import('../components/docs/Docs.vue'),
            props: true,
            name: 'docs',
        }],
    },
    {
        path: '/discover',
        redirect: '/tutorials',
    },
    {
        path: '/tutorials',
        component: AppShell,
        props(route) {
            return {
                withoutSidebar: shellNoSidebarRoutes.includes(route.name),
            };
        },
        children: [{
            path: '/tutorials',
            component: () => import('../components/tutorials/Tutorials.vue'),
            props(route) {
                return {
                    products: route.query.products,
                    runtimes: route.query.runtimes,
                };
            },
            name: 'tutorials',
        }, {
            path: '/tutorials/:tutorialSlug',
            component: () => import('../components/tutorials/Tutorial.vue'),
            props: true,
            name: 'tutorial',
        }, {
            path: '/tutorials/:tutorialSlug/l/:lessonId',
            component: () => import('../components/tutorials/lessons/Lesson.vue'),
            props: true,
            name: 'lesson',
        }],
    },
    // {
    //     path: '/screencasts',
    //     component: AppShell,
    //     children: [{
    //         path: '/screencasts',
    //         component: () => import('../components/screencasts/Screencasts.vue'),
    //         props: true,
    //         name: 'screencasts',
    //     }],
    // },
    {
        path: '/simulator',
        component: AppShell,
        children: [{
            path: '/simulator',
            component: () => import('../components/simulator/GlobalSimulator.vue'),
            props: true,
            name: 'simulator',
        }],
    },
    {
        path: '/sdks',
        component: AppShell,
        props(route) {
            return {
                withoutSidebar: shellNoSidebarRoutes.includes(route.name),
            };
        },
        children: [{
            path: '/sdks',
            component: () => import('../components/sdks/Sdks.vue'),
            props: true,
            name: 'sdks',
        }],
    },
    {
        path: '*',
        component: AppShell,
        children: [{
            path: '*',
            alias: '/404',
            component: PageNotFound,
            name: '404',
        }],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to) => {
        if (to.hash) {
            return {
                selector: to.hash,
                offset: {
                    x: 0,
                    y: 100,
                },
            };
        }

        return { x: 0, y: 0 };
    },
});

export default router;
