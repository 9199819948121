import { getDeviceStatus } from '../../utils/api/socket/iot';

import { generateMutations } from '../utils';

const defaultState = {
    isBoardConnected: false,
    newBuildAvailable: false,
    newBuildSrc: '',
    showDeviceSyncCtrl: false,
    deviceType: '',
};

const mutations = generateMutations(defaultState);

const actions = {
    async getDeviceStatus(context, params) {
        const { deviceType } = await getDeviceStatus(params);

        if (deviceType) {
            context.commit('setIsBoardConnected', true);
            context.commit('setDeviceType', deviceType);
        } else {
            context.commit('setIsBoardConnected', false);
            context.commit('setDeviceType', null);
        }
    },
};

export default {
    namespaced: true,
    state: defaultState,
    actions,
    mutations,
};
