/* global umami */
import {
    startSession as startSimulatorSession,
    endSession as endSimulatorSession,
} from '../../../utils/api/socket/simulator';

import messaging from './messaging';
import ussd from './ussd';
import payments from './payments';

const defaultState = {
    activeScreen: 'home',
    screenHistory: [],
    connected: false,
    gracefulDisconnect: false,
    connectError: '',
    phoneNumber: '',
    notification: '',
};

const simulatorResponseHandler = function fx({ context, payload }) {
    if (!payload.data) {
        console.error(new Error(`Missing payload data on paylod: ${payload}`));
        return;
    }

    const { messageType } = payload.data;

    if (messageType === undefined) {
        console.error(new Error(`Missing type on payload: ${payload}`));
        return;
    }

    switch (messageType) {
    case 'AirtimeTopup':
        context.dispatch(
            'simulator/messaging/receiveAirtime',
            payload.data.payload,
            { root: true },
        );
        break;
    case 'DataBundle':
        context.dispatch(
            'simulator/messaging/receiveMobileData',
            payload.data.payload,
            { root: true },
        );
        break;
    case 'MobilePaymentB2C':
        context.dispatch(
            'simulator/messaging/receivePayment',
            payload.data.payload,
            { root: true },
        );
        break;
    case 'OutgoingSms':
        context.dispatch(
            'simulator/messaging/receiveSms',
            payload.data.payload,
            { root: true },
        );
        break;
    case 'UssdMenu':
        context.dispatch(
            'simulator/ussd/receiveUssd',
            payload.data.payload,
            { root: true },
        );
        break;
    case 'Notification':
        context.dispatch(
            'receiveNotification',
            payload.data.payload,
        );
        break;
    case 'RegistrationResult':
        context.dispatch(
            payload.data.payload.endConnection ? 'registrationEnded' : 'registrationResult',
            payload.data.payload,
        );
        break;
    default:
    }
};

const mutations = {
    setPhoneNumber(state, data) {
        state.phoneNumber = data;
    },

    setConnected(state, data) {
        state.connected = data;
    },

    setConnectError(state, data) {
        state.connectError = data;
    },

    setGracefulDisconnect(state, data) {
        state.gracefulDisconnect = data;
    },

    setActiveScreen(state, screen) {
        state.screenHistory.push(state.activeScreen);

        switch (screen) {
        case 'home':
            state.activeScreen = screen;
            break;
        default:
            state.activeScreen = 'home';
            break;
        }
    },

    setNotification(state, data) {
        state.notification = data;
    },

    emptyScreenHistory(state) {
        state.screenHistory = [];
    },

    popScreenHistory(state) {
        state.activeScreen = state.screenHistory.pop();
    },
};

const actions = {
    async startSimulatorSession(context, phoneNumber) {
        try {
            context.commit('setPhoneNumber', phoneNumber);
            context.commit('setConnected', false);
            context.commit('setGracefulDisconnect', false);
            context.commit('setConnectError', '');

            const startSessionData = {
                phoneNumber,
                cb: (payload) => simulatorResponseHandler({
                    context,
                    payload,
                }),
            };
            await startSimulatorSession(startSessionData);

            umami.track('simulator-opened', 'sandbox');
        } catch (ex) {
            context.commit('setConnected', false);
            if (/^Bad Request/.test(ex.message)) {
                context.commit('setConnectError', ex.message);
            } else {
                context.commit('setConnectError', 'Failed to launch. Kindly try again.');
            }
        }
    },

    async endSimulatorSession({ commit, state }) {
        try {
            await endSimulatorSession(state.phoneNumber);
            commit('setGracefulDisconnect', true);
            commit('setConnected', false);
        } catch (ex) {
            console.error(ex);
        }
    },

    simulatorDisconnected({ commit, state }) {
        if (!state.gracefulDisconnect) {
            commit('setConnected', false);
            commit('setConnectError', 'Lost connection :( Kindly reconnect.');
        }
    },

    registrationResult({ commit }, payload) {
        if (payload.status === false) {
            const launchError = 'Phone number already being used in another active session.';
            commit('setConnected', false);
            commit('setConnectError', launchError);
        } else {
            commit('setConnected', true);
            commit('setConnectError', '');
        }
    },

    receiveNotification({ commit }, payload) {
        commit('setNotification', payload.message);
    },

    registrationEnded(context, payload) {
        try {
            context.commit('setConnectError', 'A new session has been started with the same number.');
            context.dispatch(
                'endSimulatorSession',
                payload,
            );
        } catch (ex) {
            console.error(ex);
        }
    },
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    modules: {
        messaging,
        ussd,
        payments,
    },
};
