import invokeImpl from './invoke';

import getEndpoint from '../endpoints';

const invoke = async (method, path, data, options = {}) => getEndpoint('yodaDocsUrl')
    .then((baseUrl) => invokeImpl(method, path, data, {
        ...options,
        baseUrl,
    }));

export const getDocsFileTree = async () => invoke('GET', '/files/tree');

export const getDocsFile = async (fileName) => invoke('GET', `/files/file?name=${fileName}`);
