<template>
    <div class="relative z-40 md:z-70">
        <div
            class="app-header w-screen fixed top-0"
            :class="{
                'bg-orange-gradient': !isTransparent,
                'bg-transparent': isTransparent,
            }">
            <div
                class="flex flex-row h-16 items-center justify-between"
                :class="{
                    'w-12/12': !isFullWidth,
                    'mx-auto': !isFullWidth,
                }">
                <div class="h-full px-4 flex flex-row lg:w-sideBarWidthLg xl:w-sideBarWidthXl items-center text-white">
                    <menuSvg
                        class="h-8 fill-current lg:hidden mr-4"
                        @click="emitOpenSidebar()" />
                    <router-link class="flex h-8" to="/">
                        <logoSvg class="h-full fill-current" />
                    </router-link>
                </div>
                <div class="relative h-full flex-grow px-4 flex flex-row justify-end items-center">
                    <AppSearch
                        class="hidden sm:block absolute transform-center-x"
                        v-if="withSearchBar && !isMobile"
                        :placement="'header'"
                    />
                    <button
                        v-if="!sessionActive"
                        @click="doSignIn"
                        class="bg-transparent border text-white py-1 px-4 ml-4 border-white rounded-md">
                        Sign In
                    </button>
                    <div
                        v-if="sessionActive"
                        class="relative">
                        <ProfileId
                            :profile="user"
                            :size="'small'"
                            :theme="'light'"
                            :compact="true"
                            class="cursor-pointer"
                            @click.native="openAvatarMenu"
                        />
                        <ul
                            ref="avatarMenu"
                            class="avatar-menu transition duration-150 absolute right-0 bg-white shadow-md rounded-md overflow-hidden min-w-48">
                            <li>
                                <div class="flex flex-col px-4 py-2 text-gray-800">
                                    <span class="text-base">{{ user.firstName }} {{ user.lastName }}</span>
                                    <span class="text-sm">{{ user.email }}</span>
                                </div>
                            </li>
                            <li>
                                <a href="https://account.africastalking.com" target="_blank" class="px-4 py-2 block border-t cursor-pointer text-sm text-gray-700 bg-white hover:bg-gray-200">
                                    Account
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapState,
    mapActions,
} from 'vuex';

import { EventBus, APP_CONFIG, setCookie } from '@/utils';

export default {
    name: 'AppHeader',

    props: {
        isTransparent: {
            type: Boolean,
            default: false,
        },
        isFullWidth: {
            type: Boolean,
            default: true,
        },
        withSearchBar: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        const isMobile = window.innerWidth < 640;

        return {
            isMobile,
            avatarMenuOpen: false,
            isSigningIn: false,
            isSigningOut: false,
        };
    },

    computed: {
        ...mapState('auth', [
            'sessionActive',
            'user',
        ]),
    },

    created() {
        this.registerBusListeners();
        this.populateSession();
    },

    beforeDestroy() {
        this.cancelBusListeners();
    },

    methods: {
        ...mapActions('auth', [
            'populateSession',
            'revokeAccessToken',
        ]),

        emitOpenSidebar() {
            EventBus.$emit('openSidebar');
        },

        doSignIn() {
            const NEST_DASHBOARD_BASE_URL = APP_CONFIG.nestDashboardBaseUrl;
            const REDIRECT_URI = `${APP_CONFIG.baseUrl}/auth/login`;
            const CLIENT_ID = APP_CONFIG.clientId;

            const authStateSecret = `__ysct${Date.now()}`;
            setCookie(APP_CONFIG.cookies.authStateSecret, authStateSecret);
            setCookie(APP_CONFIG.cookies.returnPath, window.location.href);

            const oAuthRoute = `${NEST_DASHBOARD_BASE_URL}/auth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&state=${authStateSecret}`;
            window.location = oAuthRoute;
        },

        afterCloseAvatarMenu() {
            document.removeEventListener('click', this.closeAvatarMenu);
            this.avatarMenuOpen = false;
        },

        closeAvatarMenu(evt) {
            try {
                if (this.$refs.avatarMenu.contains(evt.srcElement)) return;
            } catch (ex) { return; }
            this.$refs.avatarMenu.classList.remove('active');
            this.afterCloseAvatarMenu();
        },

        openAvatarMenu(evt) {
            evt.stopImmediatePropagation();
            if (this.avatarMenuOpen) return;
            this.$refs.avatarMenu.classList.add('active');
            document.addEventListener('click', this.closeAvatarMenu);
            this.avatarMenuOpen = true;
        },

        registerBusListeners() {
            EventBus.$on('doSignIn', this.doSignIn);
        },

        cancelBusListeners() {
            EventBus.$off('doSignIn', this.doSignIn);
        },
    },

    components: {
        logoSvg: () => import('@/assets/img/logo.svg'),
        menuSvg: () => import('@/assets/img/menu.svg'),
        AppSearch: () => import('./AppSearch.vue'),
        ProfileId: () => import('./ProfileId.vue'),
    },
};
</script>

<style lang="postcss" scoped>
.avatar-menu {
    top: 115%;
    opacity: 0;
    pointer-events: none;

    &.active {
        opacity: 1;
        pointer-events: auto;
    }
}
</style>
