import invokeImpl from './invoke';

import getEndpoint from '../endpoints';

const invoke = async (method, path, data, options = {}, useDefaultEndpoint = false) => getEndpoint('yodaApiUrl', useDefaultEndpoint)
    .then((baseUrl) => invokeImpl(method, path, data, {
        ...options,
        baseUrl,
    }));

export const requestAccessToken = async (data) => invoke(
    'POST',
    '/auth/token/exchange',
    data,
    {},
    true,
);

export const revokeAccessToken = async (data) => invoke(
    'POST',
    '/auth/token/revoke',
    data,
);
