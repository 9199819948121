import { generateMutations } from '../utils';

const defaultState = {
    projectHost: null,
    projectRuntime: null,
    projectApp: null,
    projectRunning: false,
};

const mutations = generateMutations(defaultState);

export default {
    namespaced: true,
    state: defaultState,
    mutations,
};
