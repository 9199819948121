import Vue from 'vue';
import Cookies from 'js-cookie';
import gravatar from 'gravatar';

export const APP_CONFIG = JSON.parse(process.env.VUE_APP_CONFIG);

export const EventBus = new Vue();

export const defaultQueryParams = {
    pageNumber: 0,
    count: 50,
};

export const formatQueryParams = (query) => Object
    .entries(query)
    .reduce((acc, [param, value]) => {
        if (Array.isArray(value)) {
            let holder = acc;
            value.forEach((v) => {
                holder += `${param}=${encodeURIComponent(v)}&`;
            });
            return holder;
        }

        return `${acc}${param}=${encodeURIComponent(value)}&`;
    }, '?')
    .replace(/&$/, '');

export const titleCase = (str) => {
    const splitStr = str.toLowerCase().split(' ');
    splitStr.forEach((value, idx) => {
        splitStr[idx] = value.charAt(0).toUpperCase() + value.substring(1);
    });
    return splitStr.join(' ');
};

export const isEmpty = (val) => {
    if (val === null || val === undefined) {
        return true;
    } if (typeof val === 'string') {
        return !val.trim().length;
    } if (typeof val === 'number') {
        return false;
    } if (Array.isArray(val)) {
        return val.length === 0;
    }
    return false;
};

export const gravatarProfiles = {
    make: (options) => {
        const {
            email,
            size = 100,
        } = options;
        return gravatar.url(email, {
            s: size,
            d: 'mp',
        }, true);
    },
};

export const getDomain = () => window.location.hostname;

export const setCookie = (key, data) => Cookies.set(key, data, {
    domain: getDomain(),
});

export const getCookie = (key) => Cookies.get(key);

export const deleteCookie = (key) => Cookies.remove(key, {
    domain: getDomain(),
});

const HELP_TOUR_COOKIE = APP_CONFIG.cookies.helpTour;
export const setHelpTourCookie = (value) => setCookie(HELP_TOUR_COOKIE, value);
export const getHelpTourCookie = () => getCookie(HELP_TOUR_COOKIE);
