import { requestAccessToken, revokeAccessToken } from '../../utils/api/http/auth';

import {
    isSignedIn,
    getUser,
    setAccessToken,
    deleteAccessToken,
} from '../../utils/auth';

import { generateMutations } from '../utils';

const defaultState = {
    sessionActive: false,
    user: null,
};

const mutations = generateMutations(defaultState);

function populateSessionImpl(commit) {
    const userIsSignedIn = isSignedIn();
    if (userIsSignedIn) {
        const user = getUser();
        commit('setSessionActive', userIsSignedIn);
        commit('setUser', user);
    }
}

function expireSessionImpl(commit) {
    deleteAccessToken();
    commit('setSessionActive', isSignedIn());
    commit('setUser', null);
}

const actions = {
    async requestAccessToken({ commit }, data) {
        const { access_token: accessToken } = await requestAccessToken(data);
        setAccessToken(accessToken);
        populateSessionImpl(commit);
    },

    async revokeAccessToken({ commit }) {
        await revokeAccessToken();
        expireSessionImpl(commit);
    },

    populateSession: ({ commit }) => populateSessionImpl(commit),

    expireSession: ({ commit }) => expireSessionImpl(commit),
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
};
